import React from "react";
import logo from "../../images/pdf_logo.png";
const CatchReceiptPDF = ({getData}) => {
  const jsonArray = JSON.parse(getData?.installment_payments);

  const arrayOfArrays = JSON.parse(jsonArray);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to get the correct month (January is 0)
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <style>
        {`
    .wrapper {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        background-color: white !important;
        // font-family: auto !important;
      }
      
      .receipt-box {
        width: 760px;
        display: flex;
        flex-direction: column;
        margin: 35px 35px;
      }
      
     .th,.td{
        padding:2px!important;
        border: 3px double ;
      }
      
      .p{
        margin :0 !important;
        color: black !important;
        padding:0px 5px!important;
        font-size:14px;
      }
      th,td{
        padding: 1px 4px !important;
        text-align:center;
        font-size: 12px;
        word-wrap: break-word;
        height: 16px;
        border:0.5px solid gray;
      }
    `}
      </style>
      <div className="wrapper">
        <div className="receipt-box">
          <div className="actual-receipt px-4" style={{ height: "750px" }}>
            <div className="">
              <div className="container p-2 text-center">
                <img src={logo} style={{ width: "190px" }} />
                <h5 className="mt-3 fw-bold text-muted">INVOICE</h5>
              </div>
              <div className="container-fluid text-end">
                <div className="row">
                  <div className="col-6 text-center">
                    <p className="text-black mb-0 fw-bold" style={{ fontSize: "14px" }}>
                      {" "}
                      
                    </p>
                  </div>
                  <div className="col-6">
                    {/* <p className="text-black mb-0 fw-bold" style={{ fontSize: "14px" }}><span className="fw-normal">{getData.startDate}
                    </span>
                    : تاريخ الحدث 
                    </p> */}
                    <p className="text-black mb-0 fw-bold" style={{ fontSize: "14px" }}><span className="fw-normal">{getData.startDate}
                    </span>
                      : التاريخ
                    </p>
                    {/* <p className="text-black mb-0 fw-bold" style={{ fontSize: "14px" }}><span className="fw-normal">{formatDate(getData.created_at)}
                    </span>
                      : التاريخ
                    </p> */}
                    <p className="text-black mb-0 fw-bold" style={{ fontSize: "14px" }}> <span className="fw-normal">{getData.bill_invoice_no}</span>
                      : قبض رقم
                    </p>
                  </div>
                </div>
                <div>
                  <p
                    className="text-black mb-0 text-center fw-bold"
                    style={{ fontSize: "14px" }}
                  >
                    سند قبض
                  </p>
                  <table className="table m-auto" style={{ width: "25%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: "50%%", borderRight: "0px" }}>
                          دينار
                        </th>
                        <th style={{ width: "50%%" }}>فلس</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ borderTop: "0px", borderRight: "0px" }}
                        >{getData.paid.split('.')[0]}</td>
                        <td style={{ borderTop: "0px" }}> {getData.paid.split('.')[1].replace(/0+$/, '0')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="container text-end pt-3">
              <p className="text-black m-0 fw-bold" style={{ fontSize: "14px" }}> 
                  {/* <span className="fw-normal"> {getData.
                  clientName
                }</span>
                  : وصلني من السيد / السادة{" "} */}
                       { /^[\u0600-\u06FF]/.test(getData.clientName) ? (
                          <>وصلني من السيد / السادة : <span className="fw-normal">{getData.clientName}</span></>
                        ) : (
                          <><span className="fw-normal">{getData.clientName}</span> : وصلني من السيد / السادة</>
                        )}
                </p>
                <p className="text-black m-0 fw-bold" style={{ fontSize: "14px" }}>
                  : مبلغ و قدره{" "}
                </p>
                <p className="text-black m-0 fw-bold" style={{ fontSize: "14px" }}>
                  : و ذلك عن
                </p>
              </div>
              <div className="container mt-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: "25%", borderRight: "0px" }}>
                        نقدآ/شيك رقم
                      </th>
                      <th style={{ width: "25%", borderRight: "0px" }}>قيمة</th>
                      <th style={{ width: "25%", borderRight: "0px" }}>
                        مسحوب على بنك
                      </th>
                      <th style={{ width: "25%" }}>تاريخ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrayOfArrays[0]?.price !== ""?arrayOfArrays.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                      
                        <td style={{ borderTop: "0px", borderRight: "0px" }}>{rowIndex + 1}</td>
                        <td  style={{ borderTop: "0px", borderRight: "0px" }}>{row.price}</td>
                        <td style={{ borderTop: "0px", borderRight: "0px" }}>
                          {row.paymentType === '1' ? 'Cash' :
                            row.paymentType === '2' ? 'Cheque' :
                              row.paymentType === '3' ? 'Bank Transfer' :
                                row.paymentType === '4' ? 'E-Payment' : ''}
                        </td>
                        <td style={{ borderTop: "0px", borderleft: "0px" }}>{row.date}</td>
                      
                      </tr>
                    )):<tr ><td colSpan={4} style={{ borderTop: "0px"}}>No Data Found</td></tr>}
                    <tr>
                      <td style={{ fontSize: "11px", borderTop: "0px", borderRight: "0px" }}>{getData.paid}</td>
                      <td colspan="3" className="text-end fw-bold" style={{ fontSize: "11px", borderTop: "0px" }}>الاجمالي</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="container text-end pe-5 pb-5">
                <p className="text-black m-0 fw-bold" style={{ fontSize: "14px" }}>
                  توقيع المستلم
                </p>
              </div>
              <div className="container mt-5 text-center">
                <p className="text-black m-0 fw-bold" style={{ fontSize: "12px" }}>
                  قاعة الساحة البيضاء للافراح و المناسبات{" "}
                </p>
                <p className="text-black m-0 fw-bold" style={{ fontSize: "12px" }}>
                طريق المطار – عمان – الاردن
                </p>
              </div>
              {/* <div className="container-fluid text-end">
                <p className="text-black mb-0">المحترمين</p>
              </div>
              <div className="container mt-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: "15%",borderRight:"0px" }}>Clarification</th>
                      <th style={{ width: "15%",borderRight:"0px" }}>Time</th>
                      <th style={{ width: "15%",borderRight:"0px" }}>Site</th>
                      <th style={{ width: "30%",borderRight:"0px" }}>DESCRIPTION</th>
                      <th style={{ width: "15%" }}>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{borderTop:"0px",borderRight:"0px"}}>1</td>
                      <td style={{borderTop:"0px",borderRight:"0px"}}>Mark</td>
                      <td style={{borderTop:"0px",borderRight:"0px"}}>Otto</td>
                      <td style={{borderTop:"0px",borderRight:"0px"}}>@mdo</td>
                      <td style={{borderTop:"0px"}}>@mdo</td>
                    </tr>
                    <tr>
                      <td colspan="4" className="text-end fw-bold" style={{fontSize:"11px",borderTop:"0px",borderRight:"0px"}}>
                        SUB TOTAL
                      </td>
                      <td style={{fontSize:"11px",borderTop:"0px"}}>@twitter</td>
                    </tr>
                    <tr>
                      <td colspan="4" className="text-end fw-bold" style={{fontSize:"11px",borderTop:"0px",borderRight:"0px"}}>
                        DISCOUNT
                      </td>
                      <td style={{fontSize:"11px",borderTop:"0px"}}>@twitter</td>
                    </tr>
                    <tr>
                      <td colspan="4" className="text-end fw-bold" style={{fontSize:"11px",borderTop:"0px",borderRight:"0px"}}>
                        TAX 16%
                      </td>
                      <td style={{fontSize:"11px",borderTop:"0px"}}>@twitter</td>
                    </tr>
                    <tr>
                      <td colspan="4" className="text-end fw-bold" style={{fontSize:"11px",borderTop:"0px",borderRight:"0px"}}>
                        TOTAL DUE
                      </td>
                      <td style={{fontSize:"11px",borderTop:"0px"}}>@twitter</td>
                    </tr>
                  </tbody>
                </table>

                <p
                  className="mb-0  text-black text-center fw-bold"
                  style={{ fontSize: "12px" }}
                >
                  Thank you for your business!
                </p>
              </div>
              <div className="container-fluid  text-center mt-5">
                <p className="text-black mb-0">
                  قاعة الساحة البيضاء للافراح والمناسبات
                </p>
                <p className="text-black mb-0">طريق المطار – عمان – الاردن</p>
                <p className="text-black mb-0">
                  رقم التليفون : 0779767666 / 0799767666
                </p>
                <p className="text-black mb-0">ضريبة المبيعات : 1262491</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatchReceiptPDF;